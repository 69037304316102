import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "authPage" }
const _hoisted_2 = { class: "text-center mt-5 mb-4 pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_header = _resolveComponent("auth-header")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_auth_header),
    _createVNode(_component_localized_text, {
      localizedKey: "app_ResetPassword",
      text: "Reset Password",
      class: "title white d-block text-center mb-4 pb-2"
    }),
    _createVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.resetPassword()), ["prevent"]))
    }, [
      _createVNode(_component_form_field, {
        type: "email",
        modelValue: _ctx.username,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.username = $event)),
        labelKey: "app_Email",
        labelValue: "Email",
        placeholderKey: "app_Email",
        placeholderValue: "Email"
      }, null, 8, ["modelValue"]),
      _createVNode("button", {
        type: "submit",
        disabled: _ctx.isDisable,
        class: "black mt-3"
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Reset",
          text: "Reset"
        })
      ], 8, ["disabled"])
    ], 32),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_goToLogin",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/login'))),
        text: "Go to login",
        class: "fs_14 fw_600 white text_underlined"
      })
    ])
  ]))
}