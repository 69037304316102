
import { Options, Vue } from 'vue-class-component';
import { BaseUserAuthClient, CustomerAuthClient, CustomerClient } from '@/services/services';
import { StorageServices } from '@/services/StorageServices';
import AuthHeader from '../components/authHeader.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';

@Options({
    components: {
        AuthHeader
    }
})
export default class Login extends Vue {

    username: string = "";
    exhibitionId: string = store.state.exhibition.identifier;

    created() {
        var exhibition = StorageServices.getExhibition();
        
        if(exhibition)
            this.exhibitionId = exhibition.identifier;
    }

    get isDisable() {
        return !this.username;
    }

    resetPassword() {
        BaseUserAuthClient.resetPassword(this.username)
        .then(x => {
            console.log("entrato successo");
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_WeSentNewPassword", "We sent at your email address a new password!"),
                confirm: this.$localizationService.getLocalizedValue("app_Login", "Login"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.$router.push("/login");
                }
            })
        })
        .catch(y => {
            console.log("entrato errore");
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_EmailForPasswordResetNotExist", "We are sorry but your email seems not exist, please check what you wrote for accuracy"),
                confirm: this.$localizationService.getLocalizedValue("app_Retry", "Retry"),
                deny: this.$localizationService.getLocalizedValue("app_BackToHomepage", "Back to homepage"),
                confirmCb: this.$opModal.closeLast(),
                denyCb: () => {
                    this.$opModal.closeLast();
                    this.$router.push("/welcome/" + this.exhibitionId);
                }
            })
        })
    }
    
}
